<template>
  <div class="q-ma-md">
    <pro-deck
      :title="getRes('Form.Section.SiteVisitRequeset')"
      :cards="items"
      :statuses="statuses"
      v-slot="{ card, status }"
      filter-by-status
      show-no-status
    >
      <pro-deck-card
        v-bind="card"
        :status="status ? status.label : ''"
        :statusColor="status ? status.color : ''"
      />
    </pro-deck>
  </div>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";

/*eslint-disable*/
export default {
  components: { ProMenuLayout, ProDeckCard, ProDeck },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Form.Field.CompanyOne",
          status: "completed",
          date: new Date(),
          actions: [{ label: this.getRes("System.Button.View"), to: {} }],
        },
        {
          id: 2,
          title: "Form.Field.CompanyTwo",
          status: "notConfirmedYet",
          date: new Date(),
          actions: [{ label: this.getRes("System.Button.View"), to: {} }],
        },
        {
          id: 3,
          title: "Form.Field.CompanyThree",
          status: "",
          date: null,
          actions: [{ label: this.getRes("System.Button.View"), to: {} }],
        },
      ],
      statuses: [
        {
          key: "notConfirmedYet",
          label: this.getRes("Document.Status.NotConfirmedYet"),
          color: "orange",
        },
        {
          key: "completed",
          label: this.getRes("Document.Status.Completed"),
          color: "green",
        },
      ],
    };
  },
};
</script>
